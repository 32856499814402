<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-0"
  >
    <!-- タブ -->
    <v-tabs v-model="tab"
      background-color="primary"
      color="accent"
      grow
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
      <v-tab
        class="flex-column"
        v-for="shiftTab in shiftTabs"
        :key="shiftTab.id"
      >
        <span class="d-block">
          {{ shiftTab.date }}
        </span>
        <span class="d-block text-overline">
          {{ shiftTab.title }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab"
      class="pa-2"
    >
      <v-tab-item>
        <!-- 本日 出勤０ -->
        <v-row
          v-if="!castsIn.length"
        >
          <v-col cols="12">
            <v-card flat>
              <v-card-text>出勤データがありません。</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- 出勤あり -->
        <v-row dense
          v-else
        >
          <v-col cols="6" sm="4" md="3" xl="2"
            class="cast d-flex flex-wrap"
            v-for="cast in castsIn"
            :key="cast.cast_id"
          >
            <v-hover
            v-slot="{ hover }"
            open-delay="300"
            >
              <cast-card
                :siteApi="siteApi"
                :setting="setting"
                :castData="cast"
                :hover="hover"
                @bookingClicked="bookingClicked(cast)"
              ></cast-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <!-- 明日 出勤０ -->
        <v-row
          v-if="!castsInTomorrow.length"
        >
          <v-col cols="12">
            <v-card flat>
              <v-card-text>出勤データがありません。</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- 出勤あり -->
        <v-row dense
          v-else
        >
          <v-col cols="6" sm="4" md="3" xl="2"
            class="cast d-flex flex-wrap"
            v-for="cast in castsInTomorrow"
            :key="cast.cast_id"
          >
            <v-hover
            v-slot="{ hover }"
            open-delay="300"
            >
              <cast-card
                :siteApi="siteApi"
                :setting="setting"
                :castData="cast"
                :hover="hover"
                @bookingClicked="bookingClicked(cast)"
              ></cast-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <!-- 一週間 -->
        <v-row>
          <v-col cols="12"
            class="cast d-flex"
            v-for="cast in casts"
            :key="cast.cast_id"
          >
            <v-hover
            v-slot="{ hover }"
            open-delay="100"
            >
              <week-shift
                :siteApi="siteApi"
                :siteData="siteData"
                :setting="setting"
                :castData="cast"
                :hover="hover"
                @bookingClicked="bookingClicked(cast, $event)"
              ></week-shift>
            </v-hover>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <!-- 予約フォーム -->
    <v-bottom-sheet
      v-model="openBooking"
      inset
      persistent
      overlay-opacity="0.5"
    >
      <form-booking
        :siteApi="siteApi"
        :siteData="siteData"
        :setting="setting"
        :shiftInfo="shiftInfo"
        @cancel="openBooking = false"
      ></form-booking>
    </v-bottom-sheet>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import { BizHour, ApiTool, AlertApiError } from '@/module.js';
import CastCard from '@/components/_CastCard.vue'
import ShiftOfCast from '@/components/ShiftOfCast.vue'
import FormCastBooking from '@/components/_FormCastBooking.vue'

//***************************************************
//
// Component
//
//***************************************************
export default {
  components: {
    'cast-card': CastCard,
    'week-shift': ShiftOfCast,
    'form-booking': FormCastBooking,
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      shiftTabs: [
        {id:1, title:'Today', date: ''},
        {id:2, title:'Tomorrow', date: ''},
        {id:3, title:'Weekly', date: ''}
      ],
      tab: null,
      casts: [],
      castsIn: [],
      castsInTomorrow: [],
      displayShiftDays: 7,
      shiftInfo: {cast_name: ''},
      openBooking: false,
      bizHour: new BizHour(this.siteData),
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    jpDate() {
      return date =>  moment(date).format('M/D')
    },
    dateToday() {
      return moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
    },
    dateAdded() {
      return days => moment(this.bizHour.getBizOpening(new Date())).add(days, 'd').format('YYYYMMDD')
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.shiftTabs[0].date = this.jpDate(this.dateToday)
    this.shiftTabs[1].date = this.jpDate(this.dateAdded(1))
    this.shiftTabs[2].date = this.jpDate(this.dateToday) + '〜' + this.jpDate(this.dateAdded(6))

    this.getCastsData()
    .catch( error => AlertApiError(error) )
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    async getCastsData() {
      await this.getCasts()
      if (!this.casts.length) return

      const today = moment(this.bizHour.getBizOpening(new Date()))

      // キャストデータをawaitしてから
      await Promise.all([
        this.getDateShifts(today.format('YYYY-MM-DD')),
        this.getDateShifts(today.add(1, 'd').format('YYYY-MM-DD')),
        this.getRangeShifts(),
      ])
    },

    // API req: キャストデータ
    getCasts() {
      return this.apiTool.getRequest('cast/').then( results => {
        if (!results || !results.length) return

        this.casts = results.map( cast => {
          // リアクティブ保持用
          cast.shifts = []
          return cast
        })
      })
    },

    // API req: 日のシフト
    getDateShifts(dateStr) {
      return this.apiTool.getRequest('shift/date/', {date: dateStr}).then( results => {
        if (!results || !results.length) return

        const today = moment(this.bizHour.getBizOpening(new Date())).format('YYYY-MM-DD')

        //取得したシフトをキャストデータに紐付け
        results.forEach( shift => {
          this.casts.forEach( cast => {
            if (cast.cast_id === shift.cast_id) {
              if (dateStr === today) {
                this.castsIn.push({...cast, ...shift})
              } else {
                this.castsInTomorrow.push({...cast, ...shift})
              }
            }
          })
        })
      })
    },

    // API req: 範囲のシフト
    getRangeShifts() {
      const fromDate = this.bizHour.getBizOpening(new Date())
      const toDate = this.bizHour.getBizClosing(moment(new Date()).add(this.displayShiftDays, 'd'))
      const query = {fromDate: fromDate, toDate: toDate}

      return this.apiTool.getRequest('shift/range/', query).then( results => {
        const dummyDate = moment(fromDate).clone()
        const dummyWeek = []

        //日数分ダミーのシフトデータを用意
        for (let i = 0; i < this.displayShiftDays; i++) {
          dummyWeek.push({
            shift_date: dummyDate.format('YYYY-MM-DD'),
            start_at: dummyDate.format('YYYY-MM-DD HH:mm'),
            end_at: dummyDate.format('YYYY-MM-DD HH:mm'),
          })
          dummyDate.add(1, 'd')
        }

        //キャスト毎にシフトを挿入
        this.casts.forEach( cast => {
          // Object.assign(cast.shifts , dummyWeek)
          cast.shifts = [...dummyWeek]

          if (results.length) {
            results.forEach( shift => {
              if (cast.cast_id === shift.cast_id) {
                //対象日にシフトデータを上書き
                const dayIndex = cast.shifts.findIndex( day => day.shift_date === moment(shift.shift_date).format('YYYY-MM-DD') )
                cast.shifts[dayIndex] = shift
              }
            })
          }
        })
      })
    },

    //予約ボタンクリック：フォームオープン
    bookingClicked(cast, event) {
      this.shiftInfo.cast_id = cast.cast_id
      this.shiftInfo.cast_name = cast.name

      //週間シフトから
      if (event) {
        this.shiftInfo.shift_date = event.shift_date
        this.shiftInfo.start_at = event.start_at
        this.shiftInfo.end_at = event.end_at
      } else {
        this.shiftInfo.shift_date = cast.shift_date
        this.shiftInfo.start_at = cast.start_at
        this.shiftInfo.end_at = cast.end_at
      }
      this.openBooking = true
    },
  }
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}
.v-tab .text-overline {
  line-height: 1.5em;
}
.v-tabs-bar.primary .v-tab--active {
  font-weight: bold;
  color: inherit !important;
}
</style>
