<template>
  <v-card
    class="week-shift d-flex flex-grow-1"
    :elevation="hover ? 20 : 2"
    color="transparent"
  >
    <v-card
      color="primary"
      :to="{
        name: 'profile',
        params: {
          castId: castData.cast_id,
          castData: castData
        }
      }"
    >
      <v-img
        class="rounded"
        :src="castData.image_url"
        :lazy-src="lazyload_img"
        min-width="60"
        max-width="80"
        max-height="100"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="accent"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-title
        class="cast-name pa-0 pl-1 text-caption font-weight-bold"
      >
        {{ castData.name }}
      </v-card-title>
    </v-card>

    <v-card
      class="d-flex  flex-grow-1 ml-2"
      color="secondary"
    >
      <v-card-actions class="flex-grow-1 justify-space-around px-0"
        v-for="day in castData.shifts"
        :key="day.shift_date"
      >
        <v-item-group class="shift">
          <v-item>
            <v-sheet
              class="d-flex flex-column align-center justify-space-between"
              height="inherit"
              color="transparent"
            >
              <span
                class="text-body-2"
                :class="$vuetify.breakpoint.smAndDown && 'text-caption'"
              >{{ jpDate(day.shift_date) }}</span>
              <v-sheet
                class="d-flex"
                :class="$vuetify.breakpoint.smAndDown && 'flex-column align-center'"
                color="transparent"
                v-html="getShiftStatus(day)"
              >
              </v-sheet>
              <v-btn
                class="text-overline"
                :disabled="!day.cast_id"
                x-small
                depressed
                color="accent"
                @click="$emit('bookingClicked', day)"
              >予約
              </v-btn>
            </v-sheet>
          </v-item>
        </v-item-group>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ja'
import { LAYZLOAD_IMG } from '@/literals.js';
import { BizHour } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    castData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      lazyload_img: LAYZLOAD_IMG,
      bizHour: new BizHour(this.siteData),
    };
  },

  computed: {
    extractTime() {
      return datime => moment(datime).format('HH:mm')
    },
    jpDate() {
      return date => {
        const bizToday = moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
        if (moment(date).format('YYYYMMDD') === bizToday) {
          return '本日'
        } else {
          return moment(date).format('M/D(dd)')
        }
      }
    },
    getShiftStatus() {
      return shiftData => {
        if (shiftData.cast_id !== undefined) {
          return  `<span>${this.extractTime(shiftData.start_at)}</span>` +
                  `<span>〜</span>` +
                  `<span>${this.extractTime(shiftData.end_at)}</span>`
        } else {
          return '<span class="my-2">未定</span>'
        }
      }
    }
  },

  created() {
  },

  methods: {
  }
};
</script>

<style scoped>
.cast-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,.3);
}
.shift {
  height: 100%;
}
>>> .shift span {
  line-height: 1em;
}
</style>
